<template>
  <div class="section__lk-content container-shadow fill-white">
    <div class="section__lk-title">Библиотека материалов</div>
    <div class="form-item">
      <label for="tab"><span>Выберите раздел с материалами</span></label>
      <v-select :searchable="false" :clearable="false" id="tab" :loading="!tabs.length" :disabled="!tabs.length" label="name" name="course" :options="tabs" v-model="tab">
        <template slot="option" slot-scope="item">
          <div>{{ item.name }}</div>
        </template>
      </v-select>
    </div>
    <div class="form-item">
      <label for="course"><span>Выберите курс</span></label>
      <v-select :searchable="false" :clearable="false" id="course" :loading="!courses.length" :disabled="!courses.length" label="title" name="course" :options="courses" v-model="course">
        <template slot="option" slot-scope="item">
          <div>{{ item.title }}</div>
        </template>
      </v-select>
    </div>

    <div v-if="!docs.length" class="section__lk-empty">пока у вас нет ни одного материала</div>
    <template v-else>
      <div v-for="doc in docs" :key="doc.id" class="section__lk-course">
        <div>
          <img src="/pic/icon-book-open.svg" class="icon">
        </div>
        <div class="section__lk-course-title">
          <div class="label">PDF</div>
          {{ doc.title }}
        </div>
        <div class="section__lk-course-date">
          <div class="label">Дата:</div>
          {{ doc.date | formatDate('DD.MM.YYYY') }}
        </div>
        <a class="section__lk-course-link container-shadow"></a>
      </div>
    </template>
  </div>
</template>

<script>
import ApiService from "../../../../services/api.service";

export default {
  name: "TutorLibs",
  data() {
    return {
      courses: [],
      docs: [],
      tabs: [],
      course: null,
      tab: null
    }
  },
  async mounted() {
    this.tabs = (await ApiService.get('tutordocument/tabs')).data
    this.tab = this.tabs[0]
    this.courses = (await ApiService.get('tutordocument/courses')).data
    this.course = this.courses[0]
    this.docs = (await ApiService.get('tutordocuments/' + this.course.id)).data.docs
  }
}
</script>

<style scoped>

</style>